@media screen and (max-width: 768px) {
  #alertMsg {
    width: 100%;
    height: auto;
  }

  #alertMsg-wrapper {
    position: relative;
  }

  /* common styles start */
  .fs-12px {
    font-size: 12px;
  }

  .sm-m-a {
    margin-left: auto;
    margin-right: auto;
  }

  .sm-m-0 {
    margin: 0 !important;
  }

  .sm-malr-1 {
    margin: auto 1rem;
  }

  .sm-mb-1-5 {
    margin-bottom: 1.5rem;
  }

  .sm-mb-2 {
    margin-bottom: 2rem;
  }

  .sm-mt-1-5 {
    margin-top: 1.5rem;
  }

  .sm-mt-1 {
    margin-top: 1rem;
  }

  .sm-mt-8px {
    margin-top: 8px !important;
  }

  .sm-m-b-1rem {
    margin-bottom: 1rem;
  }

  .sm-p-1-5 {
    padding: 1.5rem;
  }

  .sm-fs-1 {
    font-size: 1rem !important;
  }

  .sm-fs-14px {
    font-size: 14px;
  }

  .sm-fs-12px {
    font-size: 12px;
  }

  .sm-txt-c {
    text-align: center;
  }

  .sm-f-col {
    flex-flow: column;
  }

  .sm-d-f {
    display: flex;
  }

  .sm-f-row {
    flex-flow: row !important;
  }

  .sm-f-spb {
    justify-content: space-between;
  }
  .sm-justify-center {
    justify-content: center;
  }
  .sm-justify-between {
    justify-content: space-between;
  }
  .sm-f-j-c {
    justify-content: center !important;
  }

  .sm-f-grow {
    flex-grow: 1;
  }
  .sm-mt-4 {
    margin-top: 1rem;
  }
  .sm-a-i-c {
    align-items: center !important;
  }

  .sm-r-gap1-5 {
    row-gap: 1.5rem;
  }

  .sm-r-gap-1 {
    row-gap: 1rem !important;
  }

  .sm-c-gap-1 {
    column-gap: 1rem;
  }

  .sm-gap-5px {
    gap: 3px !important;
  }

  .sm-p-0 {
    padding: 0 !important;
  }
  .sm-p-4 {
    padding: 1rem;
  }
  .sm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm-pt-4 {
    padding-top: 1rem;
  }
  .sm-p-2 {
    padding: 0.5rem;
  }
  .sm-p-1px {
    padding: 1px !important;
  }

  .sm-p-5px {
    padding: 5px !important;
  }

  .sm-p-1 {
    padding: 1rem !important;
  }

  .sm-p1-5 {
    padding: 24px;
  }

  .sm-mx-w-full {
    max-width: 100% !important;
  }

  .sm-h-100 {
    height: 100% !important;
  }

  .sm-h-a {
    height: auto !important;
  }

  .sm-of {
    overflow: scroll;
  }

  .sm-of-a {
    overflow: auto;
  }

  .sm-p-abs {
    position: absolute !important;
  }

  .sm-br-r-n {
    border-radius: 0px !important;
  }

  .landing-title {
    width: 220px;
  }

  .landing-f-col {
    flex-flow: column;
  }

  /* .sm-w-full {
        width: 100% !important;
    } */

  .sm-txt-a-l {
    text-align: left;
  }
  .sm-text-center {
    text-align: center;
  }

  .sm-txt-a-e {
    text-align: end;
  }

  .sm-c-col {
    flex-flow: column;
  }

  .sm-f-a-c {
    align-items: center;
  }

  sm-j-c {
    justify-content: center;
  }
  .sm-shadow-n {
    box-shadow: none !important;
  }

  .sm-float-n {
    float: none !important;
  }
  .sm-flex-col {
    flex-flow: column;
  }
  /*common styles end  */
  /* header start */
  /* #desk-link-wrapper {
        display: none !important;
    } */

  .sm-fs-2rem {
    font-size: 2rem !important;
  }

  /* header end */
  /* home page start */
  #search-voyages {
    width: 100%;
  }

  /* .search-input-wrapper {
        flex-flow: column;
        row-gap: 1rem;
    } */

  /* .search-input {
        width: 100%;
    } */

  #home-page {
    padding: 0;
  }

  #home-page-wrapper {
    padding: 2rem 1rem;
    height: fit-content;
    position: relative !important;
  }

  #search-btn {
    width: 196px;
  }

  .departure-return-wrapper > div {
    /* width: 100%; */
  }

  /* home page end */

  /* payment start */
  #payment input {
    width: 100%;
    max-width: none;
  }

  #payment .wrapper {
    width: 100%;
  }

  /* .meal {
        height: 96px !important;
        flex-flow: row !important;
    }

    .meal-round {
        height: 130px !important;
        flex-flow: row !important;
    } */

  /* .meal img,
    .meal-round img {
        width: 88px;
        height: 88px;
    } */

  /* payment end */
  /* passenger detail srt */
  /* #passenger-dts input {
        width: 100%;
    } */

  /* passenger detail end */

  /* dashboard start */
  #credit-available {
    width: 100% !important;
  }

  #dashboard-box-wrapper .bookings {
    width: 48% !important;
  }

  #dashboard-box-wrapper {
    justify-content: center;
    row-gap: 1.5rem;
    column-gap: 4%;
  }

  #booking-table .remove {
    display: none;
  }

  /* dashbord end */
  /* meals */
  /* #meals {
        padding-bottom: 70px;
    } */

  .mealAddBtn {
    width: 85px !important;
  }

  .mealAddBtn2 {
    width: 112px !important;
  }

  /* .mealDesWrapper {
        width: calc(100% - 88px);
        column-gap: .5rem;
    }

    .mealNameWrapper {
        width: calc(100% - 85px);
        overflow: hidden;
    } */
}

@media screen and (max-width: 900px) {
  .sm-seat-box {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    font-size: 10px;
  }
}
