* {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  font-family: 'Poppins';
}

button,
input {
  font-family: 'Poppins';
}

input:is([type="date"]) {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}