:root {
  --maxWidth: 1280px;
  --primaryColor: #DF7435;
  --gray: #424242;
  --inputGray: #E9E9E9;
  --bgGray: #D9D9D9;
}

/* Nav Link start */
.nav-link {
  text-decoration: none;
  color: black;
}

/* Nab Link end */

/* Home page start */
#home-page-wrapper {
  height: 688px;
  padding: 1rem;
}

.landing-title {
  width: 440px;
}

/* .landing-flex-row {
  flex-flow: row;
} */

#search-voyages {
  background-color: white;
  width: 488px;
  height: fit-content;
  padding: 2rem;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  box-sizing: border-box;
}

.search-input {
  width: 196px;
  height: 48px;
  border: none;
  border-radius: 8px;
  background: var(--Gray-1, #D9D9D9);
  padding: 0 1rem;
  box-sizing: border-box;
}

.search-label {
  font-size: 14px;
  font-weight: 500;
}

.pax-drop-bg {
  background-repeat: no-repeat;
  background-position-x: right;
  background-origin: content-box;
  background-position-y: center;
}

/* Home page end */

/* Voyages process start */
.process-round {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--primaryColor);
}

/* Voyages process end */
#register input:not([type="checkbox"]) {
  width: 448px;
}

/* register end */

/* payment start */
#payment {
  max-width: 800px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
}

#payment input {
  max-width: 352px;
  height: 48px;
  width: 352px;
  border-radius: 8px;
  border: none;
  background-color: var(--inputGray);
  padding-left: 24px;
}

#payment label::after {
  content: '*';
  color: red;
}

.star::after {
  content: '*';
  color: red;
}

/* payment end */
/* passenger detail srt */
#passenger-dts input {
  /* width: 448px; */
  margin-top: 8px;
}

/* passenger detail end */

/* dashboard start */
thead th {
  font-weight: 500;
}

tbody td {
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid var(--bgGray);
}

table tr {
  height: 68px;
}

tbody tr:last-child td {
  border: none;
}

.round {
  color: #FF7A00;
  background-color: #FFE9D4;
}

.travel-type {
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 8px;
}

.one-way {
  color: #0085FF;
  background-color: #CFE8FF;
}

.economy {
  background: #00E0FF;
}

.first-class {
  background: #FFD700;
}

.deluxe {
  background: #12E700;
}

.special-needs {
  background: #00E2E2;
}

/* dashbord end */