@media screen and (min-width: 769px) and (max-width: 1024px) {
  /* common styles start */

  .md-f-col {
    flex-flow: column;
  }

  .md-br-r-n {
    border-radius: 0px !important;
  }

  .txt-center {
    text-align: center;
  }

  .md-m-t {
    margin-top: 1rem;
  }

  .md-row-gap1-5 {
    row-gap: 1rem;
  }

  /* common styles end */
  .txt-center {
    text-align: center;
  }

  /* home page start */
  .landing-f-col {
    flex-flow: column;
  }

  #home-page-wrapper {
    height: calc(100vh - 80px - 3rem);
  }

  /* .search-input-wrapper>div {
        width: 48%;
    } */

  .departure-return-wrapper > div {
    width: 48%;
  }

  #search-voyages {
    width: 100%;
    height: 100%;
  }

  .search-input {
    width: 100%;
  }

  #search-btn {
    width: 196px;
  }

  /* home page end */
  /* register start */
  /* #register {
        width: 100%;
    } */

  /* register end */
  /* payment start */
  #payment {
    max-width: 416px;
  }

  /* payment end */

  /* dashbord start */
  #dashboard-box-wrapper {
    justify-content: center;
    column-gap: 4rem;
    row-gap: 2rem;
  }

  /* #booking-table tr td:nth-child(7),
    #booking-table th:nth-child(7) {
        display: none;
    } */

  /* dashbord end */
}

@media screen and (min-width: 1025px) {
  .search-input-wrapper > div {
    width: 48%;
  }

  #home-page-wrapper {
    top: 64px !important;
    height: calc(100% - 98px) !important;
  }

  .c-e {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  #login {
    width: 416px;
    left: auto;
    right: 10%;
    height: fit-content;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  #register {
    height: fit-content;
  }
}

@media screen and (max-width: 1024px) {
  .sm-w-full {
    width: 100% !important;
  }

  .smd-w-full {
    width: 100% !important;
  }

  .smd-fs-1-5 {
    font-size: 1.5rem !important;
  }

  .search-input-wrapper {
    flex-flow: column;
    row-gap: 1rem;
  }

  .search-input {
    width: 100%;
  }

  .meal {
    height: 96px !important;
    flex-flow: row !important;
  }

  .meal-round {
    height: 150px !important;
    flex-flow: row !important;
  }

  .meal img,
  .meal-round img {
    width: 88px;
    height: 88px;
  }

  .show-boxshadow {
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }

  .remove-overlay {
    background-color: white !important;
  }

  .smd-box-shadow {
    box-shadow: none !important;
  }

  .md-f-col {
    flex-flow: column;
  }

  .smd-m-0 {
    margin: 0 !important;
  }

  .md-w-full {
    width: 100% !important;
  }

  .md-d-n {
    display: none;
  }

  #login-bg {
    background-image: none !important;
    position: static !important;
  }

  #menu-icon {
    display: block !important;
  }

  /* #desk-link-wrapper {
        display: none !important;
    } */
  .smd-d-f {
    display: flex;
  }

  .smd-c-gap-1 {
    column-gap: 1rem;
  }

  .smd-f-col {
    flex-flow: column;
  }

  .smd-w-full {
    width: 100%;
  }

  .smd-h-100 {
    height: 100% !important;
  }

  .smd-r-gap-1 {
    row-gap: 1rem !important;
  }

  .smd-f-a-c {
    align-items: center;
  }

  .smd-p-5px {
    padding: 5px !important;
  }

  .smd-p-0 {
    padding: 0 !important;
  }

  .smd-f-row {
    flex-flow: row !important;
  }

  .smd-m-0 {
    margin: 0 !important;
  }

  .smd-mt-1 {
    margin: 1rem !important;
  }

  .smd-m-a {
    margin-left: auto;
    margin-right: auto;
  }

  .smd-f-grow {
    flex-grow: 1;
  }

  /* register start */
  #register input:not([type="checkbox"]) {
    width: 100%;
  }

  #pax-details input:is([type="date"]) {
    min-width: 100%;
  }

  #passenger-dts input {
    width: 100%;
  }

  /* register end */
  #meals {
    padding-bottom: 100px;
  }

  .mealDesWrapper {
    width: calc(100% - 88px);
    column-gap: 0.5rem;
  }

  .mealNameWrapper {
    width: calc(100% - 85px);
    overflow: hidden;
  }
}
