:root {
  --maxWidth: 1280px;
  --primaryColor: #df7435;
  --gray: #424242;
  --inputGray: #e9e9e9;
  --bgGray: #d9d9d9;
}

/* animation start */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes expand {
  from {
    height: 0;
  }

  to {
    height: 300px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes popup {
  from {
    scale: 0.5;
  }

  to {
    scale: 1;
  }
}

@keyframes scale {
  from {
    scale: 0;
  }

  to {
    scale: 1;
  }
}

#desk-link-wrapper a.active {
  color: var(--primaryColor);
  font-weight: 700;
}

.logout-popup {
  animation: popup 0.1s;
}

.fade {
  animation: fade 1s;
}

.expand {
  animation: expand 2s;
}

.scale {
  animation: scale 0.5s;
}

.loader {
  width: 35px;
  height: 35px;
  border: 4px dotted #f3f3f3;
  border-radius: 50%;
  margin: auto;
  animation: spin 2s linear infinite;
}

/* animatin end */

/* common styles start */
.tooltip {
  position: relative;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.b-500 {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.b-600 {
  font-weight: 600;
}

.b-700 {
  font-weight: 700;
}

.fs-10px {
  font-size: 10px;
}

.fs-8px {
  font-size: 8px;
}

.fs-12px {
  font-size: 12px;
}
.text-sm {
  font-size: 14px;
}
.text-xs {
  font-size: 12px;
}
.text-xl {
  font-size: 20px;
}
.text-gray {
  color: var(--gray);
}
.text-primary {
  color: var(--primaryColor);
}
.fs-14px {
  font-size: 14px;
}

.fs-20px {
  font-size: 20px;
}

.fs-1-5 {
  font-size: 1.5rem;
}

.fs-2rem {
  font-size: 2rem;
}

.fs-4rem {
  font-size: 4rem;
}

.pt-1-5 {
  padding-top: 1.5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-8 {
  padding: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mr-2 {
  margin-right: 2rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mlr-1 {
  margin: 0 1rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-0 {
  margin-right: 0;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}

.mb-1-5 {
  margin-bottom: 1.5rem;
}

.mt-1-5 {
  margin-top: 1.5rem;
}

.mt-8px {
  margin-top: 8px;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mtb-1-5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mtb-1 {
  margin: 1rem 0;
}

.mtb-2 {
  margin: 2rem 0;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.m-1 {
  margin: 1rem;
}
.text-center {
  text-align: center;
}
.text-white {
  color: white;
}
.ptb-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ptb-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.plr-1-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p1-5 {
  padding: 1.5rem;
}

.p-1 {
  padding: 1rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p-1-5 {
  padding: 1.5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.plr-1 {
  padding: 0 1rem;
}

.pl-1-5 {
  padding-left: 1.5rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-1 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-0 {
  padding-right: 0rem;
}
.br-r-8 {
  border-radius: 8px;
}

.br-r-1 {
  border-radius: 1rem;
}

.br-r-12 {
  border-radius: 12px;
}
.rounded-sm {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.white {
  color: white;
}

.br-n {
  border: none;
}
.text-end {
  text-align: end;
}
/* .txt-center {
    text-align: center;
  } */
.primary-bg {
  background-color: var(--primaryColor);
}
.text-primary {
  color: var(--primaryColor);
}
.border {
  border-width: 1px;
  border-style: solid;
}
.border-primary {
  border-color: var(--primaryColor);
}
.primary {
  color: var(--primaryColor);
}

.gray {
  color: var(--gray);
}

.red {
  color: red;
}

.black {
  color: black;
}

.input-gray {
  background-color: var(--inputGray);
}

.shadow {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}

.popup {
  box-shadow: 0px 0px 42px 3px #0000001a;
}

.m-a {
  margin-left: auto;
  margin-right: auto;
}

.box-size {
  box-sizing: border-box;
}

.d-f {
  display: flex;
}
.flex {
  display: flex;
}
.flex-row {
  flex-flow: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.f-col {
  flex-flow: column;
}
.flex-col {
  flex-flow: column;
}
.f-wrap {
  flex-wrap: wrap;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.txt-a-r {
  text-align: right;
}

.txt-a-c {
  text-align: center;
}

.txt-end {
  text-align: end;
}

.a-i-c {
  align-items: center;
}

.r-gap-1 {
  row-gap: 1rem;
}

.r-gap-8 {
  row-gap: 2rem;
}

.c-gap-8 {
  column-gap: 2rem;
}

.c-gap-1-5 {
  column-gap: 1.5rem;
}

.r-gap-1-5 {
  row-gap: 1.5rem;
}

.c-gap-1 {
  column-gap: 1rem;
}

.c-cont {
  justify-content: center;
  align-items: center;
}

.f-spb {
  justify-content: space-between;
}
.flex-end {
  justify-content: end;
}
.f-j-e {
  justify-content: end;
}
.gap-x-4 {
  column-gap: 1rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.gap-x-8 {
  column-gap: 2rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.f-a-c {
  align-items: center;
}

.f-j-c {
  justify-content: center;
}

.f-end {
  align-items: end;
}

.f-c {
  align-items: center;
}
.grow {
  flex-grow: 1;
}
.j-c-e {
  justify-content: end;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: end;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content;
}
.w-max {
  width: max-content;
}
.w-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
}

.h-48 {
  height: 48px;
}
.hidden {
  display: none;
}
.p-r {
  position: relative;
}

.p-a {
  position: absolute;
}
.p-6 {
  padding: 1.5rem;
}
.p-c {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.bg-white {
  background-color: white;
}
.bg-red {
  background-color: red;
}
.bg-primary {
  background: var(--primaryColor);
}
.bg-gray {
  background-color: var(--bgGray);
}
.txt-dec-n {
  text-decoration: none;
}

.txt-dec-u {
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
/* common styles end */
/* common component start*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
}

.btn {
  width: 171px;
  height: 48px;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 8px;
  border: none;
  font-weight: 500;
}

.msg-btn {
  width: 80px;
  height: 40px;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 8px;
  border: none;
  font-weight: 500;
}

.br-btn {
  border: 1px solid var(--primaryColor);
  background-color: white;
  border-radius: 8px;
  width: 196px;
  height: 48px;
  font-weight: 500;
  color: var(--primaryColor);
}

.round-btn {
  width: 30px;
  height: 30px;
  background-color: var(--primaryColor);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  width: 352px;
  height: 48px;
  background-color: var(--inputGray);
  border-radius: 8px;
  border: none;
}

.link {
  color: var(--primaryColor);
  text-decoration: underline;
}

.inp-n-w {
  border-radius: 8px;
  background-color: var(--inputGray);
  border: none;
  height: 48px;
}

.mealBtn {
  border-radius: 4px;
  border: none;
  padding: 0.5rem;
}

.btn:hover,
#search-btn:hover {
  background-color: white !important;
  color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor);
}

.br-btn:hover {
  background-color: var(--primaryColor) !important;
  color: white;
}

.voyage-card {
  transition: scale 0.3s;
}

.voyage-card:hover {
  scale: 1.02;
}
.scaleVoyage:hover {
  scale: 1.02;
}
.transition {
  transition: scale 0.3s;
}
.list-items li:hover {
  background-color: #d9d9d9;
}

.paymentHover:hover {
  border-color: var(--primaryColor) !important;
  scale: 1.1;
}

.paymentHover {
  transition: scale 0.5s;
}

.deselectSeat {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #05063d;
  border: 1px solid #ed040400;
  right: -7px;
  top: -7px;
}

.hover-bg-blue:hover {
  background-color: var(--primaryColor);
  color: white;
}
.hover-bg-blue:hover .pax {
  background-color: white;
}
/* common component end*/
@media screen and (min-width: 769px) {
  .lg-txt-a-r {
    text-align: right;
  }
  .md-justify-end {
    justify-content: end;
  }
  .md-flex-row {
    flex-flow: row;
  }
  .md-flex {
    display: flex;
  }
}

@media screen and (min-width: 900px) {
  .seat-box {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }
}

@media screen and (min-width: 1024px) {
  #backBtn {
    display: none;
  }

  .mx-w-448px {
    width: 448px !important;
  }

  #passenger-dts input {
    width: 448px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 1088px) {
  #pax-details {
    padding-bottom: 70px;
    height: auto !important;
    position: fixed;
    overflow: scroll;
  }
}

@media screen and (max-height: 908px) {
  #pax-details {
    height: auto !important;
    margin: 1rem auto;
    overflow: scroll;
  }
}

@media (min-width: 640px) {
  .md-hidden {
    display: none;
  }
  .md-block {
    display: block;
  }
  .md-text-end {
    text-align: end;
  }
  .md-mr-4 {
    margin-right: 1rem;
  }
  .md-justify-between {
    justify-content: space-between;
  }
}
